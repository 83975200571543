// Temp Hero Styling

.hero-wrapper {
  .content {
    position: relative;
    min-height: 240px;
    text-align: center;

    @include respond-to(tablet-min) {
      min-height: 300px;
    }

    @include respond-to(laptop-min) {
      min-height: 360px;
    }

    @include respond-to(desktop-min) {
      min-height: 440px;
    }
  }

  h1 {
    color: $white;
    position: absolute;
    padding: 0 16px;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

    @include respond-to(desktop-min) {
      top: 50%;
      padding: 0 4%;
    }
  }

  &.home {
    .content {
      min-height: 360px;

      @include respond-to(tablet-min) {
        min-height: 420px;
      }

      @include respond-to(laptop-min) {
        min-height: 540px;
      }

      @include respond-to(desktop-min) {
        min-height: 600px;
      }
    }

    h1 {
      top: 45%;
    }
  }

  .item {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .carousel-control {
    background: $primaryColor;
    height: 32px;
    width: 32px;
    opacity: 0.6;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    padding: 0;
    color: $white;
    border: 2px solid $primaryColor;
    transition: opacity 0.25s ease-in-out;

    @include respond-to(tablet-min) {
      height: 64px;
      width: 64px;
      padding: 4px;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
      transform-origin: center center;
      transform: translateY(-50%) rotate(180deg);
    }

    &:hover,
    &focus {
      text-shadow: none;
      opacity: 1;
      border: 2px solid $primaryColor;

      &.left {
        .icon-chevron-arrow-left {
          animation: arrow-left-anim 0.8s linear 0s infinite;
        }
      }

      &.right {
        .icon-chevron-arrow-left {
          animation: arrow-right-anim 0.8s linear 0s infinite;
        }
      }
    }

    &:active {
      text-shadow: none;
    }
  }
}
