.cost-section {
  padding: 0 0 64px;

  @include respond-to(tablet-min) {
     padding: 40px 0 56px;
  }

  @include respond-to(desktop-min) {
    padding: 80px 0 104px;
  }

  .content {
    p {
      font-size: 16px;

      span {
        font-family: $mainFontBold;
      }
    }

    a {
      font-family: $mainFontBold;
      font-size: 16px;
      position: relative;
      color: $primaryColor;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .contact-col {
    color: $primaryColor;
    text-align: center;

    @include respond-to(phone-max) {
      margin-bottom: 40px;
    }

    .inner {
      padding: 54px 16px;
      background: rgba($secondaryColor, 0.12);

      @include respond-to(phone-max) {
        padding: 40px 16px;
        margin: 0 -16px;
      }
    }

    p {
      display: inline-block;
      font-family: $mainFontBold;
      font-size: 24px;

      @include respond-to(desktop-min) {
        font-size: 32px;
      }

      &:first-child {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: $primaryColor;
        }
      }
    }

    a {
      position: relative;
      color: $primaryColor;

      &::before {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $primaryColor;
        transition: width 0.25s ease-in-out;
      }

      &:hover {
        &::before {
          width: 0;
        }
      }
    }
  }
}
