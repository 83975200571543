.contact-strip {
  p {
    font-size: 16px;

    @include respond-to(desktop-min) {
      font-size: 20px;
    }
  }

  a {
    color: $white;
    transition: text-shadow 0.25s ease-in-out;

    &:hover,
    &:focus {
      text-decoration: none;
      text-shadow: 0 0 1px rgba($white, 0.4);
    }
  }
}
