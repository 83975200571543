.overflow-y-hidden {
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100vh;

}

.no-transition {
  transition: none !important;
}

.no-side-mar {
  margin-left: 0;
  margin-right: 0;
}

.no-mar {
  margin: 0;
}

.no-pad {
    padding: 0;

    &-t {
      padding-top: 0;

      &-desktop {
        @media only screen and (min-width: $grid-float-breakpoint) {
          padding-top: 0;
        }
      }
    }

    &-r {
      padding-right: 0;

      &-desktop {
        @media only screen and (min-width: $grid-float-breakpoint) {
          padding-right: 0;
        }
      }
    }

    &-b {
      padding-bottom: 0;

      &-desktop {
        @media only screen and (min-width: $grid-float-breakpoint) {
          padding-bottom: 0;
        }
      }
    }

    &-l {
      padding-left: 0;

      &-desktop {
        @media only screen and (min-width: $grid-float-breakpoint) {
          padding-left: 0;
        }
      }
    }

  &-desktop {
    @media only screen and (min-width: $grid-float-breakpoint) {
      padding: 0;
    }
  }
}

.col-xs-half {
  @media only screen and (min-width: 480px) and (max-width: $phone-max-size) {
    width: 50%;
  }
}

.no-txt-trans {
  text-transform: none;
}

.justify-center {
  display: block;
  @include respond-to(laptop-min) {
    display: flex;
    justify-content: center;
  }
}

.sm-padding-btm {
  padding-bottom: 35px;
}

.sm-padding-btm-mobile {
  padding-bottom: 35px;

    @include respond-to(laptop-min) {
      padding-bottom: 0px;
    }
}

.sm-padding-top-mobile {
  padding-top: 30px;

    @include respond-to(laptop-min) {
      padding-top: 0px;
    }
}
