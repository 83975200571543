.feature-pods {
  position: relative;
  // /margin-top: -24px;

  @include respond-to(tablet-min) {
    // /margin-top: -64px;
  }

  .row-hook {
    @include respond-to(tablet-min) {
      background: $primaryColor;
      box-shadow: 0 0 20px 0 rgba(104, 104, 104, 1);
    }
  }
}

.feature-pod {
  background: $primaryColor;
  box-shadow: 0 0 20px 0 rgba(104, 104, 104, 1);
  padding: 0 24px 24px 24px;
  text-align: center;
  font-family: $mainFontBold;
  color: $white;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0 0 56px;
  transition: background 0.25s ease-in-out;

  @media only screen and (min-width: 480px) and (max-width: $phone-max-size) {
    width: calc(50% - 8px);
  }

  @include respond-to(tablet-min) {
    margin: -64px 0 0;
    box-shadow: none;
    border-right: 1px solid lighten($primaryColor, 5);
  }

  @include respond-to(desktop-min) {
    padding: 0 32px 32px 32px;
  }

  &:nth-of-type(1) {
    margin-top: -24px;

    @media only screen and (min-width: 480px) and (max-width: $phone-max-size) {
      margin-top: -24px;
      margin-right: 8px;
    }

    @include respond-to(tablet-min) {
      margin-top: -64px;
    }
  }

  &:nth-of-type(2) {
    @media only screen and (min-width: 480px) and (max-width: $phone-max-size) {
      margin-top: -24px;
      margin-left: 8px;
    }
  }

  &:nth-of-type(3) {
    @media only screen and (min-width: 480px) and (max-width: $phone-max-size) {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  &:nth-of-type(4) {
    margin-bottom: 0;
    border: 0;

    @media only screen and (min-width: 480px) and (max-width: $phone-max-size) {
      margin-left: 8px;
    }
  }

  p {
    font-size: 14px;
    margin: 0;

    @include respond-to(desktop-min) {
      font-size: 16px;
    }
  }

  .icon {
    background: $secondaryColor;
    display: block;
    margin: -32px auto 24px;
    padding: 8px;
    height: 64px;
    width: 64px;
    transition: transform 0.6s ease-in-out,
                box-shadow 0.6s ease-in-out,
                color 0.6s ease-in-out,
                background 0.6s ease-in-out;

    @include respond-to(laptop-min) {
      padding: 16px;
      height: 80px;
      width: 80px;
      margin-top: -40px;
    }

    &-placeholder {
      transform-origin: center center;
      transition: transform 0.6s ease-in-out;
    }

    &-body-left-arrow {
      transform: translateX(-2px);
    }

    &-body-right-arrow {
      transform: translateX(2px);
    }

    &-time-btn {
      transition: transform 0.25s ease-in-out;
    }

    &-time-inner-right {
      transform-origin: 24px 27px;
      fill: $secondaryColor;
      transition: fill 0.6s ease-in-out,
                  transform 0.4s ease-in-out;
    }

    &-time-inner-right-b {
      transform-origin: 50% 56.26%;
      opacity: 1;
      fill: $secondaryColor;
      transform: scale(10);
    }

    &-doctor-hat {
      opacity: 0;
      transform: scale(1.1);
      transform-origin: 24px 14px;
      transition: opacity 0.4s ease-in-out 0.6s;
    }

    &-doctor-sign {
      transition: opacity 0.4s ease-in-out 0.6s;
    }
  }

  &:hover {
    background: darken($primaryColor, 2);

    .icon {
      transform: scale(1.08) translateY(-10%);
      box-shadow: 0 0 10px rgba(darken($primaryColor, 40), 0.4);
      background: $white;
      color: $primaryColor;

      &-placeholder {
        transition: transform 0.8s ease-in-out 0.6s;
        transform: rotate(180deg);
      }

      &-body-left-arrow {
        animation: left-arrow-anim 1.2s ease-in-out 0s infinite both;
      }

      &-body-right-arrow {
        animation: right-arrow-anim 1.2s ease-in-out 0s infinite both;
      }

      &-time-inner-right {
        fill: $white;
        transform: rotate(180deg) scale(1.1);
        transition: fill 0.6s ease-in-out,
                    transform 0.8s ease-in-out 0.8s;
      }

      &-time-inner-right-b {
        transition: fill 0.6s ease-in-out;
        fill: $white;
        opacity: 0;
      }

      &-time-btn {
        transition: transform 0.25s ease-in-out 0.6s;
        transform: translateY(2px);
      }

      &-doctor-hat {
        opacity: 1;
      }

      &-doctor-sign {
        opacity: 0;
      }
    }
  }
}
