.accordion {
  margin: 24px 0 64px;

  @include respond-to(desktop-min) {
    margin: 0 0 64px;
  }

  .panel {
    border-radius: 0;
    border: none;
    box-shadow: none;
    padding: 0;
    border-top: 1px solid rgba($mainGrey, 0.1);
    background: transparent;

    &:last-child {
      border-bottom: 1px solid rgba($mainGrey, 0.1);
    }

    & + .panel {
      margin: 0;
    }
  }

  .icon {
    position: absolute;
    top: 20px;
    right: 12px;
    height: 24px;
    width: 24px;
    margin: 0;

    transform-origin: center center;
    transform: rotate(90deg);

    transition: transform 0.4s ease-in-out;

    @include respond-to(desktop-min) {
      top: 20px;
    }
  }

  .panel-heading {
    position: relative;
    padding: 0;
    transition: background 0.25s ease-in-out;

    &:hover,
    &:focus {
      .panel-title {
        a {
          text-shadow: none;

          &.collapsed {
            background: darken($lightGrey, 2);
            padding-left: 24px;
          }
        }
      }
    }

    & + .panel-collapse {
      > .panel-body {
        border: 0;
      }
    }
  }

  .panel-title {
    font-size: 16px;

    @include respond-to(desktop-min) {
      font-size: 18px;
    }

    a {
      padding: 20px 48px 20px 16px;
      display: block;
      text-decoration: none;
      transition: padding 0.25s ease-in-out,
                  background 0.25s ease-in-out;

      &.collapsed {
        .icon {
          transform: rotate(0deg);
        }
      }
    }
  }

  .panel-body {
    padding: 0 16px 40px;

    p {
      &:last-child {
        margin: 0;
      }

      span {
        font-family: $mainFontBold;
      }
    }

    ul {
      margin: 0;
      padding: 0 0 0 16px;
    }

    li {

    }
  }
}
