.why-pod {
  margin: 16px 0;

  @media only screen and (min-width: 640px) and (max-width: $phone-max-size) {
    width: 50%;
  }

  @include respond-to(desktop-min) {
    margin: 32px 0;
  }

  .icon {
    position: absolute;
    left: 16px;
    top: 0;
    height: 56px;
    width: 56px;
    padding: 8px;
    background: $secondaryColor;
    color: $white;
    transition: background 0.25s ease-in-out,
                box-shadow 0.25s ease-in-out;

    @include respond-to(desktop-min) {
      height: 64px;
      width: 64px;
    }
  }

  .content {
    padding-left: 80px;

    @include respond-to(desktop-min) {
      padding-left: 104px;
    }
  }

  .icon-placeholder {
    transform-origin: center center;
    transition: transform 0.25s ease-in-out;
  }

  &:hover {
    .icon {
      background: darken($secondaryColor, 10);
      box-shadow: 0 0 4px rgba(darken($secondaryColor, 80), 0.6);
    }

    .icon-placeholder {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
}
