.split-section {
  padding: 0;

  @include respond-to(phone-max) {
    padding: 0;
    //margin: 0 -16px;
  }

  @include respond-to(tablet-min) {
    @include gradient-horizontal($primaryColor, $secondaryColor, 50%, 50%);
  }

  .split-imgs {
    background: $white;
  }

  .content {
    color: $white;
    position: static;

    @include respond-to(phone-max) {
      background: $primaryColor;
      padding: 0;
      width: 100%;
      display: block;
    }

    /* @include respond-to(tablet-min) {
      padding: 56px 56px 40px;
    }

    @include respond-to(laptop-min) {
      padding: 56px 64px 40px;
    }

    @include respond-to(desktop-min) {
      padding: 64px 16px 48px 80px;
    } */

    &:last-child {
      @include respond-to(phone-max) {
        background: $secondaryColor;
        padding-bottom: 40px;
      }
    }

    .split-content-0 {
      @include respond-to(phone-max) {
        //background: $primaryColor;
        //margin: -16px;
        padding: 0 16px;
      }
    }

    .list {
      @include respond-to(phone-max) {
        padding: 24px 16px 40px 56px;
      }

      @include respond-to(tablet-min) {
        padding: 56px 56px 40px 104px;
      }

      @include respond-to(laptop-min) {
        padding: 56px 64px 40px 124px;
      }

      @include respond-to(desktop-min) {
        padding: 64px 16px 48px 124px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        font-size: 16px;
        position: relative;
        padding-bottom: 24px;

        @include respond-to(desktop-min) {
          padding-bottom: 32px;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .vs-col-img {
      background: $white;
      width: 100%;
      display: block;
      //padding: 0 16px;

      @include respond-to(phone-max) {

      }

      @include respond-to(tablet-min) {
        margin: 0 -16px;
      }
      //height: 300px;

      /* &::before {
        content: "";
        background: red;
        width: 50%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      } */
    }
  }

  .inner {
    @include respond-to(tablet-min) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .icon {
    position: relative;
    color: $white;
    background: $primaryColor;
    box-shadow: 0 0 0px 4px rgba($primaryColor, 1);
    width: 24px;
    height: 24px;
    padding: 0;
    border: 2px solid $white;
    position: absolute;
    left: -40px;
    top: 4px;
    transition: background 0.4s ease-in-out,
                color 0.4s ease-in-out;

    @include respond-to(tablet-min) {
      left: -40px;
    }

    @include respond-to(laptop-min) {
      left: -48px;
    }

    /* @include respond-to(desktop-min) {
      top: 4px;
      width: 32px;
      height: 32px;
      left: -62px;
    } */
  }

  &.bg-white-primary {
    @include respond-to(tablet-min) {
      @include gradient-horizontal($white, $primaryColor, 50%, 50%);
    }

    .content {
      color: $mainGrey;

      @include respond-to(phone-max) {
        background: $white;
      }

      &:last-child {
        color: $white;

        @include respond-to(phone-max) {
          background: $primaryColor;
        }
      }
    }
  }
}
