.social-icons {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    float: left;
    padding: 0px 8px;

    a {
      width: 32px;
      height: 32px;
      float: left;
      border: 2px solid $secondaryColor;
      color: $secondaryColor;
      opacity: 0.8;
      transition: color 0.25s ease-in-out,
                  background 0.25s ease-in-out,
                  opacity 0.25s ease-in-out;

      &:hover,
      &:focus {
        background: $secondaryColor;
        color: $white;
        opacity: 1;
      }
    }
  }

  &.light-social {
    li {
      a {
        border: 2px solid $white;
        color: $white;

        &:hover,
        &:focus {
          background: $white;
          color: $primaryColor;
        }
      }
    }
  }

  &.navbar-social {
    float: left;
    width: 100%;
    text-align: center;

    @media only screen and (min-width: $grid-float-breakpoint) {
      padding: 0 16px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: auto;
    }

    @include respond-to(desktop-min) {
      padding-left: 32px;
    }

    li {
      float: none;
      display: inline-block;
      padding: 16px 8px 24px;

      @media only screen and (min-width: $grid-float-breakpoint) {
        float: left;
        padding: 0 8px;
      }

      a {
        @media only screen and (min-width: $grid-float-breakpoint) {
          border-color: $secondaryColor;
          color: $secondaryColor;
          opacity: 1;
        }

        &:hover,
        &:focus {
          @media only screen and (min-width: $grid-float-breakpoint) {
            background: $secondaryColor;
            color: $white;
          }
        }
      }
    }
  }
}
