.four-zero-four {
  @include respond-to(phone-max) {
    padding: 140px 16px;
  }

  .inner  {
    @include respond-to(phone-max) {
      text-align: center;
    }

    @include respond-to(tablet-min) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .btn {
    margin: 32px 0 0;

    @include respond-to(tablet-min) {
      margin-top: 40px;
    }
  }
}
