.contact-pod {
  margin: 0 0 32px;

  .heading {
    margin-bottom: 8px;
  }

  .email-link {
    margin: 0;
  }

  .btn {
    margin-top: 24px;

    @include respond-to(desktop-min) {
      margin-top: 32px;
    }
  }

  p {
    color: $mainGrey;
  }

  .social-icons {
    margin-top: 32px;
  }
}
