html,
body {
  font-family: $mainFont;
  -webkit-font-smoothing: antialiased;
  color: $mainGrey;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {

}

h1 {
  font-family: $mainFontBold;
  font-size: 28px;
  line-height: 1.4;
  margin: 0;

  @include respond-to(tablet-min) {
    font-size: 32px;
  }

  @include respond-to(laptop-min) {
    font-size: 40px;
  }

  @include respond-to(desktop-min) {
    font-size: 42px;
  }
}

h2,
.h2 {
  margin: 0;
  font-family: $mainFontBold;
  font-size: 24px;
  line-height: 1.4;
  text-transform: uppercase;

  @include respond-to(desktop-min) {
    font-size: 28px;
  }

  &.small-mob {
    @include respond-to(phone-max) {
      font-size: 20px;
    }
  }

  &.no-text-transform {
    text-transform: none;
  }
}

h3,
.h3 {
  font-family: $mainFontBold;
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
  margin: 0 0 10px;

  &.big-mob {
    @include respond-to(phone-max) {
      font-size: 20px;
    }
  }

  @include respond-to(desktop-min) {
    font-size: 20px;
  }
}

h4,
.h4 {
  font-family: $mainFontBold;
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
  margin: 0 0 10px;

  @include respond-to(desktop-min) {
    font-size: 20px;
  }
}

p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 16px;

  &.big-desktop {
    @include respond-to(desktop-min) {
      font-size: 16px;
    }
  }
}

a {
  color: $mainGrey;
  text-decoration: none;
  transition: text-shadow 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: $mainGrey;
    text-decoration: none;
    text-shadow: 0 0 1px rgba($mainGrey, 0.4);
  }

  &.light {
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      text-shadow: 0 0 1px rgba($white, 0.4);
    }
  }
}
