$grid-gutter-width: 32px;
$container-tablet: (100%) !default;
$container-desktop: (100%) !default;
$container-large-desktop: (1228px + $grid-gutter-width) !default;
$screen-md: 992px;
$screen-lg: 1280px;
$grid-float-breakpoint: $laptop-min-size !default;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;
$icon-font-path: "/fonts/" !default;

// Core variables and mixins
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Custom grid framework
@import "mixins/grid-framework";

// Reset and dependencies
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

