.humble-signature {
  margin: 32px 0 0;

  p {
    font-size: 12px;
    margin: 0;
  }

  .icon {
    position: relative;
    height: 12px;
    width: 63px;
    display: block;
    margin: 8px auto 0;
    color: $white;
    cursor: url('/images/components/heart-cursor.svg'), auto;
  }
}
