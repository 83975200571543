$mq-extend-flag: true !global;
//*****************************************
//  UTILITIES
//*****************************************
@mixin cf() {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

//*****************************************
//  POSITIONING
//*****************************************
@mixin center-hor( $offset: 0) {
  @include left(50%);
  @include ltr {
    @include transform( translate(-50%));
  }
  @include rtl {
    @include transform( translate(50%));
  }
  @if( $offset !=0) {
    @include margin-left($offset);
  }
}

@mixin center-vrt( $offset: 0) {
  top: 50%;
  @include transform( translate( 0, -50%));
  @if( $offset !=0) {
    margin-top: $offset;
  }
}

@mixin center( $offset-x: 0, $offset-y: 0) {
  top: 50%;
  left: 50%;
  @include transform( translate( -50%, -50%));
  @if( $offset-x !=0) {
    margin-left: $offset-x;
  }
  @if( $offset-y !=0) {
    margin-top: $offset-y;
  }
}

@mixin unselectable() {
  user-select: none;
}

@mixin placeholder() {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

//*****************************************
//  MOBILE / MEDIA QUERIES
//*****************************************
@mixin mobile-detect() {
  display: none;
  @media (max-width: 1024px) and (orientation: landscape), (max-width: 1024px) and (orientation: portrait) {
    display: block;
  }
}

@mixin mq( $mq) {
  $found: false;
  $mq-extend-flag: false !global;
  @each $name,
  $breakpoint in $breakpoints {
    @if $mq==$name {
      $found: true;
      @media (max-width: $breakpoint) {
        @content;
      }
    }
  }
  @if $found==false {
    @warn 'Breakpoint #{$mq} doesn\'t exist!';
  }
  $mq-extend-flag: false !global;
}

@mixin retina() {
  $mq-extend-flag: false !global;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and ( min--moz-device-pixel-ratio: 1.5), only screen and ( -o-min-device-pixel-ratio: 3/2), only screen and ( min-device-pixel-ratio: 1.5), only screen and (min-resolution: 192dpi) {
    @content;
  }
  $mq-extend-flag: true !global;
}

//*****************************************
//  HELPER MIXINS
//  Not generating CSS properties.
//*****************************************
// Converts a string or a CSS unit to number
// For reference, from here:
// http://hugogiraudel.com/2014/01/15/sass-string-to-number/
@function number($string) {
  // Matrices
  $strings: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
  $numbers: 0 1 2 3 4 5 6 7 8 9;
  // Result
  $result: 0;
  $divider: 0;
  $minus: false;
  // Looping through all characters
  @for $i from 1 through str-length($string) {
    $character: str-slice($string, $i, $i);
    $index: index($strings, $character);
    @if $character=='-' {
      $minus: true;
    }
    @else if $character=='.' {
      $divider: 1;
    }
    @else {
      @if not $index {
        $result: if($minus, $result * -1, $result);
        @return _length($result, str-slice($string, $i));
      }
      $number: nth($numbers, $index);
      @if $divider==0 {
        $result: $result * 10;
      }
      @else {
        // Move the decimal dot to the left
        $divider: $divider * 10;
        $number: $number / $divider;
      }
      $result: $result + $number;
    }
  }
  @return if($minus, $result * -1, $result);
}

// Helper for 'number' mixin
@function _length($number, $unit) {
  $strings: 'px' 'cm' 'mm' '%' 'ch' 'pica' 'in' 'em' 'rem' 'pt' 'pc' 'ex' 'vw' 'vh' 'vmin' 'vmax';
  $units: 1px 1cm 1mm 1% 1ch 1pica 1in 1em 1rem 1pt 1pc 1ex 1vw 1vh 1vmin 1vmax;
  $index: index($strings, $unit);
  @if not $index {
    @warn "Unknown unit `#{$unit}`.";
    @return false;
  }
  @return $number * nth($units, $index);
}

// Responsive
@mixin respond-to($media) {
  @if $media==phone-min {
    @media only screen and (min-width: $phone-min-size) {
      @content;
    }
  }
  @else if $media==phone-max {
    @media only screen and (max-width: $phone-max-size) {
      @content;
    }
  }
  @else if $media==tablet-min {
    @media only screen and (min-width: $tablet-min-size) {
      @content;
    }
  }
  @else if $media==tablet-max {
    @media only screen and (max-width: $tablet-max-size) {
      @content;
    }
  }
  @else if $media==laptop-min {
    @media only screen and (min-width: $laptop-min-size) {
      @content;
    }
  }
  @else if $media==laptop-max {
    @media only screen and (max-width: $laptop-max-size) {
      @content;
    }
  }
  @else if $media==desktop-min {
    @media only screen and (min-width: $desktop-min-size) {
      @content;
    }
  }
  @else if $media==desktop-max {
    @media only screen and (max-width: $desktop-max-size) {
      @content;
    }
  }
  @if $media==only-phone {
    @media only screen and (min-width: $phone-min-size) and (max-width: $phone-max-size) {
      @content;
    }
  }
  @else if $media==only-tablet {
    @media only screen and (min-width: $tablet-min-size) and (max-width: $tablet-max-size) {
      @content;
    }
  }
  @else if $media==only-laptop {
    @media only screen and (min-width: $laptop-min-size) and (max-width: $laptop-max-size) {
      @content;
    }
  }
  @else if $media==only-desktop {
    @media only screen and (min-width: $desktop-min-size) and (max-width: $desktop-max-size) {
      @content;
    }
  }
}
