.one-col-with-sidebar-section {
  margin-bottom: 16px;

  @include respond-to(tablet-min) {
    margin-bottom: 16px;
  }

  @include respond-to(desktop-min) {
    margin-bottom: 40px;
  }

  .heading {
    color: $primaryColor;

    @include respond-to(tablet-min) {
      margin-bottom: 56px;
    }

    @include respond-to(desktop-min) {
      margin-bottom: 80px;
    }
  }

  .content {
    @include respond-to(phone-max) {
      margin-bottom: 16px;
    }

    img {
      @include respond-to(phone-max) {
        width: 360px;
        max-width: 100%;
      }

      @include respond-to(tablet-min) {
        with: auto;
        max-width: 80%;
      }

      @include respond-to(desktop-min) {
        max-width: 100%;
      }
    }
  }

  .inner {
    display: block;

    @include respond-to(tablet-min) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .sidebar {
    color: $white;

    ul {
      background: $secondaryColor;
      list-style: none;
      margin: 0 0 0 16px;
      padding: 32px 0;

      @include respond-to(tablet-min) {
        margin: 0;
      }
    }

    li {
      position: relative;
      font-family: $mainFontBold;
      font-size: 20px;
      width: 100%;
      text-transform: uppercase;
      font-size: 16px;
      padding: 16px 16px 16px 40px;
      transition: padding 0.4s ease-in-out;

      &:hover {
        padding-left: 56px;

        .index {
          background: $white;
          color: $primaryColor;
        }
      }

      @include respond-to(desktop-min) {
        font-size: 20px;
      }

      .index {
        text-align: center;
        float: left;
        background: $primaryColor;
        border: 2px solid $primaryColor;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        bottom: 0;
        width: 40px;
        height: 40px;
        line-height: 32px;
        padding: 0px 0 4px;
        transition: background 0.25s ease-in-out,
                    color 0.25s ease-in-out;
      }
    }
  }
}
