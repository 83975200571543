.feature-item {
  position: relative;
  margin: 0 0 16px;

  @include respond-to(tablet-min) {
    margin: 0 0 32px;
  }

  .inner-content {
    position: relative;
    padding: 0 0 0 40px;

     @include respond-to(desktop-min) {
        padding-left: 56px;
     }
  }

  .icon {
    position: relative;
    color: $white;
    width: 20px;
    height: 20px;
    padding: 0;
    border: 1px solid white;
    position: absolute;
    left: 0px;
    top: 4px;
    transition: background 0.4s ease-in-out,
                color 0.4s ease-in-out,
                box-shadow 0.4s ease-in-out;

    @include respond-to(desktop-min) {
      top: 8px;
      width: 24px;
      height: 24px;
    }
  }

  &:hover,
  &:focus {
    .icon {
      background: $white;
      color: darken($secondaryColor, 5%);
      box-shadow: 2px 2px 4px rgba(darken($secondaryColor, 28), 0.3);
    }
  }
}
