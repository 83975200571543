.gmaps-section {
  position: relative;
  display: block;
  width: 100%;
  min-height: 320px;

  @include respond-to(tablet-min) {
    min-height: 360px;
  }

  @include respond-to(desktop-min) {
    min-height: 460px;
  }

  &.has-contact-pod {
    min-height: 0;

    @include respond-to(tablet-min) {
      min-height: 0;
    }

    @include respond-to(desktop-min) {
      min-height: 0;
    }

    .gmaps-wrapper {
      min-height: 280px;

      @include respond-to(tablet-min) {
        min-height: 100%;
      }
    }

    .contact-pod-holder {
      margin: 280px 0 0;

      @include respond-to(tablet-min) {
        margin: 54px 0;
      }

      @include respond-to(desktop-min) {
        margin: 112px 0;
      }

      .contact-pod {
        margin: 0;
      }

      .heading {
        margin-bottom: 8px;
        color: $mainGrey;

        h2 {
          @media only screen and (max-width: 479px) {
            font-size: 20px;
          }
        }
      }

      .inner {
        background: $white;
        padding: 40px 0 54px;

        @include respond-to(tablet-min) {
          padding: 40px 16px;
          box-shadow: 0 0 2px rgba($black, 0.2);
        }

        @include respond-to(desktop-min) {
          padding: 64px 32px;
        }
      }
    }
  }

  .gmaps-wrapper {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
}
