.contact-form {
  .heading {
    margin-bottom: 24px;

    @include respond-to(tablet-min) {
      margin-bottom: 48px;
    }

    @include respond-to(desktop-min) {
      margin-bottom: 56px;
    }
  }

  .left-input {
    @media only screen and (min-width: 480px) {
      padding-right: 8px;
    }
  }

  .right-input {
    @media only screen and (min-width: 480px) {
      padding-left: 8px;
    }
  }

  .form-group {
    margin-bottom: 24px;
  }

  .form-control {
    border-radius: 2px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 16px;
    border-color: lighten($black, 59);
    color: $mainGrey;
    transition: border 0.25s ease-in-out,
                box-shadow 0.25s ease-in-out;

    &:focus {
      border-color: $secondaryColor;
      box-shadow: 0 0 10px rgba($secondaryColor, 0.4);
    }

    &::placeholder {
      color: lighten($black, 78);
    }
  }

  textarea {
    &.form-control {
      max-width: 100%;
      margin-bottom: 32px;
    }
  }

  .btn-main {
    margin-bottom: 16px;
  }
}

.form-messages {
  display: none;
  font-size: 16px;
  padding: 24px 0 0;
  text-align: center;

  &__success {
    color: #5cb85c;
  }

  &__failure {
    color: #d9534f;
  }
}
