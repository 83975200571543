.navbar-main {
  position: relative;
  width: 100%;
  margin: 0;
  border: none;
  background-color: $white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

  @media only screen and (min-width: $grid-float-breakpoint) {
    border-radius: 0;
  }
}

.navbar-brand {
  height: auto;
  padding: 8px 16px;

  @media only screen and (min-width: $desktop-min-size) {
    padding: 16px;
  }

  img {
    height: 40px;

    @media only screen and (min-width: $grid-float-breakpoint) {
      height: 56px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1140px) {
      height: 48px;
    }
  }
}

.nav-bottom {
  text-align: center;

  @media only screen and (min-width: $grid-float-breakpoint) {
    margin: 64px 0 0;
    min-height: 48px;
    text-align: left;
  }

  @media only screen and (min-width: 992px) and (max-width: 1140px) {
    margin: 56px 0 0;
  }

  &::after {
    @media only screen and (min-width: $grid-float-breakpoint) {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      height: 48px;
      background: $primaryColor;
    }
  }

  .navbar-nav {
    li {
      &:first-child {
        &::before {
          @media only screen and (min-width: $grid-float-breakpoint) {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 24px 26px;
            border-color: transparent transparent $primaryColor transparent;
            position: absolute;
            left: 0;
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}

.navbar-collapse {
  overflow-y: auto;
  background-color: $primaryColor;
  border-top: 1px solid darken($primaryColor, 10);

  @media only screen and (min-width: $grid-float-breakpoint) {
    background-color: transparent;
    border: none;
  }

  &::before {
    @media only screen and (min-width: $grid-float-breakpoint) {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $primaryColor;
      display: block;
      height: 24px;
    }
  }
}

.navbar-nav {
  margin: 0 -16px;
  background: $primaryColor;
  text-align: center;

  @media only screen and (min-width: $grid-float-breakpoint) {
    margin: 0;
  }

  > li {
    > a {
      position: relative;
      font-size: 14px;
      line-height: 16px;
      padding: 16px;
      color: $white;
      border-bottom: 1px solid darken($primaryColor, 4);
      border-top: 1px solid lighten($primaryColor, 4);
      transition: background 0.25s ease-in-out;

      @media only screen and (min-width: $grid-float-breakpoint) {
        border: none;
        position: relative;
        font-size: 14px;
        padding: 16px 12px;
      }

      @media only screen and (min-width: 992px) and (max-width: 1140px) {
        font-size: 12px;
        padding: 16px 8px;
      }

      @media only screen and (min-width: 1096px) {
        font-size: 14px;
      }

      @include respond-to(desktop-min) {
        font-size: 16px;
      }

      &::after {
        @media only screen and (min-width: $grid-float-breakpoint) {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 5px;
          margin: 0 2px;
          background: $secondaryColor;
          transition: width 0.25s ease-in-out;
        }
      }

      &:hover,
      &:focus {
        background: lighten($primaryColor, 1);

        @media only screen and (min-width: $grid-float-breakpoint) {
          background: transparent;
        }

        &::after {
          @media only screen and (min-width: $grid-float-breakpoint) {
            width: 100%;
            width: calc(100% - 2px);
          }
        }
      }
    }

    &.open,
    &.active {
      > a {
        text-shadow: 0 0 1px rgba(255, 255, 255 , 1),
                     0 0 1px rgba(255, 255, 255 , 1);
        border-bottom: 1px solid lighten($primaryColor, 4);
        border-top: 1px solid darken($primaryColor, 4);
        background: lighten($primaryColor, 4);

        @media only screen and (min-width: $grid-float-breakpoint) {
          border: none;
          background: transparent;
        }

        &::after {
          width: 100%;
          width: calc(100% - 2px);
        }
      }
    }
  }

  &.nav-primary {
    @media only screen and (min-width: $grid-float-breakpoint) {
      position: relative;
      z-index: 1;
    }

    @for $i from 0 through 21 {
      $max-width: $i * 8;
      $min-width: $max-width - 7;
      $margin-right: ((176px - $max-width) / 2) - 8;

      @if $i == 0 {
        $min-width: ($max-width - 7) * 0;
      }

      @if $i == 21 {
        $margin-right: 0;
      }

      @media only screen and (min-width: 1280px + $min-width) and (max-width: 1280px + $max-width) {
        margin-right: $margin-right;
      }
    }
  }

  &.lang-selector {
    @media only screen and (min-width: $grid-float-breakpoint) {
      position: relative;
      z-index: 2;
      padding: 0 0 0 8px;
      width: auto;
    }

    @include respond-to(desktop-min) {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0 8px;
    }

    > li {
      overflow-y: hidden;
      float: left;
      width: 50%;

      @media only screen and (min-width: $grid-float-breakpoint) {
        width: auto;
      }

      > a {
        font-size: 12px;
        padding: 16px 12px 16px 48px;
        transition: all .25s ease-in-out;

        @media only screen and (min-width: $grid-float-breakpoint) {
          padding: 16px 12px;
        }

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-150%, -50%, 0);
          display: block;
          margin: 0 auto;
          border: 1px solid $white;
          border-radius: 50%;
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
          width: 24px;
          height: 24px;
          background: url('/images/components/greek-flag.svg') no-repeat center center;
          transition: opacity .25s ease-in-out,
                      bottom .25s ease-in-out;

          @media only screen and (min-width: $grid-float-breakpoint) {
            top: auto;
            left: 50%;
            bottom: -50%;
            opacity: 0;
            transform: translate3d(-50%, 50%, 0);
          }
        }

        &::after {
          height: 2px;
        }

        &:hover {
          @media only screen and (min-width: $grid-float-breakpoint) {
            color: transparent;
            text-shadow: none;
          }

          &::before {
            opacity: 1;
            bottom: 50%;
          }
        }
      }

      &:last-child {
        &::before {
          @media only screen and (min-width: $grid-float-breakpoint) {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            height: 12px;
            width: 1px;
            background: $white;
            transform: translateY(-50%);
            opacity: 0.4;
          }
        }

        > a {
          &::before {
            background: url('/images/components/en-flag.svg') no-repeat center center;
          }
        }
      }
    }
  }
}

$hamburger-color: $white;
$hamburger-height-icon: 20px;
$hamburger-width-line: 34px;
$hamburger-height-line: 4px;
$hamburger-transition-time: 0.4s;
$hamburger-rotation: 45deg;
$hamburger-translateY: ($hamburger-height-icon / 2);
$hamburger-translateX: 0;

.hamburger-wrap {
  position: relative;
  float: right;
  margin: 32px 0 0;

  @media only screen and (min-width: $grid-float-breakpoint) {
    display: none;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 24px 28px;
    border-color: transparent transparent $primaryColor transparent;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  &::after {
    content: "";
    width: 2000px;
    height: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: $primaryColor;
  }
}

.navbar-toggle {
  float: none;
  margin: 0;
  padding: 12px 16px 16px;
  border: none;
  border-radius: 0;
  background: $primaryColor;
  position: relative;

  .inner {
    position: relative;
    width: $hamburger-width-line;
    height: $hamburger-height-icon;
  }

  .line {
    display: block;
    background: $hamburger-color;
    width: $hamburger-width-line;
    height: $hamburger-height-line;
    position: absolute;
    left: 0;
    border-radius: ($hamburger-height-line / 2);
    transition: all $hamburger-transition-time;

    &.line-1 {
      top: 0;
    }

    &.line-2 {
      top: 50%;
    }

    &.line-3 {
      top: 100%;
    }
  }

  &:hover,
  &:focus {
    outline: 0;

    .line-1 {
      transform: translateY($hamburger-height-line / 4 * -1);
    }

    .line-3 {
      transform: translateY($hamburger-height-line / 4);
    }
  }

  &[aria-expanded="true"] {
    .line-1 {
      transform: translateY($hamburger-translateY)
                 translateX($hamburger-translateX)
                 rotate($hamburger-rotation);
    }

    .line-2 {
      opacity: 0;
    }

    .line-3 {
      transform: translateY($hamburger-translateY * -1)
                 translateX($hamburger-translateX)
                 rotate($hamburger-rotation * -1);
    }
  }
}

.nav-top {
  @media only screen and (min-width: $grid-float-breakpoint) {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    float: right;
    width: auto;
    height: 64px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1140px) {
    height: 54px;
  }
}

.location-link {
  position: relative;
  display: inline-block;
  font-size: 14px;
  margin: 24px 0 0;
  padding: 0 26px 0 32px;
  text-align: center;
  color: $white;
  text-decoration: none;

  @media only screen and (min-width: $grid-float-breakpoint) {
    float: left;
    color: $darkGrey;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  @media only screen and (min-width: 992px) and (max-width: 1140px) {
    font-size: 12px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform:rotateX(55deg);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    margin: 8px 0 0 2px;
    opacity: 0;
    box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.4);

    @media only screen and (min-width: $grid-float-breakpoint) {
      box-shadow: 0 0 1px 2px rgba($primaryColor, 0.4);
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    background: url('/images/components/pin-icon-white.svg') no-repeat left top;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    margin: -6px 0 0;

    @media only screen and (min-width: $grid-float-breakpoint) {
      background: url('/images/components/pin-icon.svg') no-repeat left top;
      background-size: 24px 24px;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;

    @media only screen and (min-width: $grid-float-breakpoint) {
      color: darken($darkGrey, 10);
    }

    &::before {
      animation: pin-pulsate 1s ease-out 0s infinite;
    }
  }
}

.location-header {
  display: inline;
  margin-left: 10px;
}
