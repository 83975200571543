@mixin btn-style($btn-style: primary) {
  $btn-color: $primaryColor;
  $label-color: $white;

  @if $btn-style == inverse {
    $btn-color: $white;
    $label-color: $primaryColor;
  } @elseif $btn-style == secondary  {
    $btn-color: $secondaryColor;
    $label-color: $white;
  } @elseif $btn-style == tertiary  {
    $btn-color: $secondaryColor;
    $label-color: $white;
  }

  color: $label-color;
  text-shadow: none;
  box-shadow: none;
  background: $btn-color;
  font-family: $mainFontBold;
  font-size: 16px;
  line-height: 1;
  padding: 14px 30px;
  border: 2px solid $btn-color;
  transition: border 0.4s ease-in-out,
              background 0.4s ease-in-out,
              color 0.4s ease-in-out;

  &:hover,
  &:focus  {
    @if $btn-style == secondary  {
      $btn-color: $white;
      $label-color: $white;
    } @elseif $btn-style == tertiary  {
      $btn-color: $secondaryColor;
      $label-color: $white;
    }

    box-shadow: none;
    color: $btn-color;
    background-color: $label-color;
    background-position: 0;
    border-color: $btn-color;

    @if $btn-style == secondary  {
      color: $secondaryColor;
    }
  }

  &:active,
  &.active {
    box-shadow: none;
    color: $label-color;
    background-color: $btn-color;
    background-position: 0;
    border-color: $btn-color;
  }
}

.btn {
  display: block;
  border-radius: 0;
  width: 100%;
  min-width: 240px;

  @media only screen and (min-width: 480px) {
    width: auto;
    display: inline-block;
  }

  &.center-btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.btn-main {
  @include btn-style;

  &.inverse {
    @include btn-style(inverse);
  }

  &.secondary {
    @include btn-style(secondary);
  }

  &.tertiary {
    @include btn-style(tertiary);
  }
}

.btn-main.inverse {
  $btn-color: $white;
  $label-color: $primaryColor;

  @extend .btn-main;
}

.call-now-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  border: 0;
  border-radius: 0;
  font-family: $mainFontBold;
  background: $secondaryColor;
  color: $white;
  text-align: center;
  padding: 14px 32px;
  font-size: 14px;
  line-height: 1;
  margin: 0;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;

  @media only screen and (min-width: $grid-float-breakpoint) {
    min-width: 196px;
    float: right;
    position: relative;
    width: auto;
    height: 64px;
    padding: 24px 32px 24px 0;
    box-shadow: none;
    transition: padding 0.25s ease-in-out,
                background 0.25s ease-in-out;
  }

  @media only screen and (min-width: 992px) and (max-width: 1140px) {
    position: relative;
    float: right;
    height: 56px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @include respond-to(desktop-min) {
    min-width: 216px;
    font-size: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &::before {
    content: "";
    display: inline-block;
    font-size: 0;
    line-height: 0;
    width: 14px;
    height: 14px;
    background: url('/images/components/phone-icon.svg') no-repeat center center;
    background-size: 14px 14px;
    margin: 0 16px 0 0;
    transition: margin 0.25s ease-in-out;

    @media only screen and (min-width: $grid-float-breakpoint) {
      transform: scale3d(0, 0, 0);
      transform-origin: center center;
      opacity: 1;
      transition: transform 0.25s ease-in-out,
                opacity 0.25s ease-in-out;
    }
  }

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;

    @media only screen and (min-width: $grid-float-breakpoint) {
      padding-left: 16px;
      padding-right: 16px;
      background: darken($secondaryColor, 6);
    }

    &::before {
      margin-right: 8px;

      @media only screen and (min-width: $grid-float-breakpoint) {
        margin-right: 16px;
        transform: scale3d(1, 1, 1);
        opacity: 1;
      }
    }
  }
}
