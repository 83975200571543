.image-pods-section {
  h2 {
    text-transform: none;
  }

  .image {
    margin: 0 0 16px;

    img {
      @include respond-to(phone-max) {
        max-width: 180px;
        padding: 0 16px;
      }
    }
  }

  .content {
    h3 {
      font-family: $mainFont;
      font-size: 14px;
      line-height: 1.6;


      @include respond-to(phone-max) {
        padding: 0 16px;
      }
    }
  }

  .pod {
    margin-bottom: 24px;

    @include respond-to(laptop-min) {
      margin-bottom: 16px;
    }

    &:last-child {
      @include respond-to(phone-max) {
        margin-bottom: 0;
      }
    }
  }
}
