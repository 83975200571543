.before-after-carousel {
  margin: 16px 0 40px;

  @include respond-to(tablet-min) {
    margin: 16px 0 40px;
  }

  @include respond-to(desktop-min) {
    margin: 0 0 56px;
  }

  .item {
    .col {
      float: left;
      max-width: 50%;

      img {
        border-right: 1px solid $white;
      }

      &.after-col {
        img {
          border-right: none;
          border-left: 1px solid $white;
        }
      }

      &:hover,
      &:focus {
        .caption {
          padding: 8px 16px;
          background-size: 100% 100%;

          transition: transform 0.25s linear 0.4s,
                padding 0.25s ease-in-out 0.25s,
                background 0.25s linear 0.4s;

          h3 {
            color: $white;
            transition: color 0.25s linear 0.4s;
          }
        }
      }
    }

    .img-holder {
      margin: 0;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        background: rgba(255, 255, 255, 0.06);
        border-radius: 100%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }

      &:hover,
      &:focus {
        &::before {
          animation: circle-bg-anim 0.6s ease-out;
        }
      }
    }
  }

  .carousel-control {
    background: $primaryColor;
    height: 32px;
    width: 32px;
    opacity: 1;
    top: 50%;
    bottom: auto;
    transform: translateY(-100%);
    padding: 0;
    color: $white;
    border: 2px solid $primaryColor;
    transition: background 0.25s ease-in-out,
                color 0.25s ease-in-out;

    @include respond-to(tablet-min) {
      transform: translateY(-50%);
      height: 64px;
      width: 64px;
      padding: 4px;
    }

    &.left {
      left: 0;

      @include respond-to(tablet-min) {
        left: -80px;
      }
    }

    &.right {
      right: 0;
      transform-origin: center center;
      transform: translateY(-100%) rotate(180deg);

      @include respond-to(tablet-min) {
        right: -80px;
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &:hover,
    &focus {
      text-shadow: none;
      background: $white;
      color: $primaryColor;
      border: 2px solid $primaryColor;

      &.left {
        .icon-chevron-arrow-left {
          animation: arrow-left-anim 0.8s linear 0s infinite;
        }
      }

      &.right {
        .icon-chevron-arrow-left {
          animation: arrow-right-anim 0.8s linear 0s infinite;
        }
      }
    }

    &:active {
      text-shadow: none;
    }
  }

  .caption {
    @include gradient-horizontal(rgba($secondaryColor, 1), rgba($secondaryColor, 1));

    background-repeat: no-repeat;
    background-size: 100% 0%;
    background-position: left top;
    text-align: center;
    margin: 0;
    padding: 16px 16px 0;
    //background: rgba($secondaryColor, 0.8);
    transition: transform 0.25s ease-in-out 0.25s,
                padding 0.25s ease-in-out 0.25s,
                background 0.25s ease-in-out;

    h3 {
      margin: 0;
      text-transform: uppercase;
      color: $mainGrey;
      transition: color 0.25s ease-in-out;
    }
  }
}
