.section {
  padding: 56px 0 40px;

  @include respond-to(desktop-min) {
    padding: 64px 0;
  }

  &.no-pad {
    padding: 0;
  }

  &.equal-pad {
    padding: 56px 0;

    @include respond-to(desktop-min) {
      padding: 64px 0;
    }
  }

  &.mid-pad {
    @include respond-to(desktop-min) {
      padding: 80px 0 64px;
    }
  }

  &.big-pad {
    @include respond-to(desktop-min) {
      padding: 104px 0 72px;
    }
  }

  .heading {
    margin-bottom: 32px;

    &:not(.left-align) {
      @media only screen and (min-width: 480px) and (max-width: $phone-max-size) {
        max-width: 83.33333%;
        margin-left: 8.33333%;
      }
    }

    @include respond-to(desktop-min) {
      margin-bottom: 64px;
    }

    &.small-spacing-btm {
      @include respond-to(desktop-min) {
        margin-bottom: 24px;
      }
    }

    &.no-mar-btm {
      margin-bottom: 0;
    }
  }
}
