.procedure-pod {
  text-align: center;
  margin-top: 32px;

  @media only screen and (min-width: 480px) {
    padding: 0 8px;
  }

  &:hover,
  &:focus {
    .inner {
      &::before {
        border-top-color: $secondaryColor;
      }

      .img-holder {
        &::before {
          animation: circle-bg-anim 0.8s ease-out;
        }
      }

      span {
        color: $white;
        padding: 16px 0 0 6px;
        font-size: 24px;

        &::before {
          width: 57px;

        }
      }
    }
  }

  .inner {
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 2;

    @media only screen and (max-width: 479px) {
      max-width: 260px;
    }

    .img-holder {
      display: inline-block;
      vertical-align: top;
      margin: 0 0 16px;
      position: relative;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 100%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 40px 40px 0 0;
      z-index: 2;
      border-color: $lightGrey transparent transparent transparent;
      transition: border 0.4s ease-in-out;
    }

    span {
      position: absolute;
      top: -18px;
      left: 0;
      color: green;
      width: 40px;
      height: 40px;
      text-align: left;
      font-size: 36px;
      line-height: 1;
      color: $secondaryColor;
      z-index: 2;
      transition: font-size 0.4s ease-in-out,
                  padding 0.4s ease-in-out,
                  color 0.4s ease-in-out;

      &::before {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 72px;
        height: 2px;
        background: $secondaryColor;
        transform-origin: 0 0;
        transform: rotate(-45deg);
        transition: width 0.25s ease-in-out;
      }
    }
  }
}
