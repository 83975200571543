.footer {
  text-align: center;
  color: $white;
  padding: 64px 0 80px;

  @include respond-to(laptop-min) {
    padding: 64px 0;
  }

  .branding-footer {
    display: inline-block;
    margin: 0 0 24px;

    @include respond-to(laptop-min) {
      margin: 0 0 40px;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 250px;
      opacity: 0.5;
      transition: opacity 0.25s ease-in-out;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  p {
    line-height: 1.6;
    margin-bottom: 0;

    &:nth-of-type(2) {
      margin: 0 0 36px;
    }
  }
}
