.two-col-section {
  padding: 0;
  position: relative;

  .image {
    padding: 0;
    display: block;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media only screen and (min-width: 480px) {
      height: 320px;
    }

    @include respond-to(tablet-min) {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
    }
  }

  .heading {
    margin-bottom: 24px;

    h2 {
      text-transform: none;
    }
  }

  .content {
    padding: 40px 16px 24px;

    @include respond-to(tablet-min) {
      padding: 56px 56px 40px;
    }

    @include respond-to(laptop-min) {
      padding: 56px 64px 40px;
    }

    @include respond-to(desktop-min) {
      padding: 64px 16px 48px 80px;
    }
  }

  &.icon-list {
    color: $darkGrey;
    background: transparent;

    .content {
      @include respond-to(phone-max) {
        padding: 24px 16px 40px 56px;
      }
    }

    .inner {
      @include respond-to(tablet-min) {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .image {
      display: block;
      height: auto;

      @include respond-to(tablet-min) {
        position: absolute;
        left: 0;
        width: 50%;
      }
    }

    img {
      position: relative;
      display: block;
      margin: 40px auto 0;
      max-height: 260px;

      @include respond-to(tablet-min) {
        margin: 0 auto;
        padding: 64px 0 0;
        top: 50%;
        transform: translateY(-50%);
        max-height: unset;
      }
    }

    .heading {
      color: $primaryColor;
      margin-bottom: 16px;

      @include respond-to(desktop-min) {
        margin-bottom: 40px;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-bottom: 20px;
        position: relative;

        @include respond-to(desktop-min) {
          padding-bottom: 40px;
        }

        &:hover,
        &:focus {
          .icon {
            background: $primaryColor;
            color: $white;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 6px;
          left: -30px;
          height: 100%;
          width: 2px;
          background: $primaryColor;

          @include respond-to(laptop-min) {
            left: -38px;
          }

          @include respond-to(desktop-min) {
            left: -48px;
          }
        }

        &:last-child {
          &:before {
            display: none;
          }
        }

        h3 {
          color: $primaryColor;
        }
      }
    }

    .icon {
      position: relative;
      color: $primaryColor;
      background: $white;
      box-shadow: 0 0 0px 4px rgba($white, 1);
      width: 24px;
      height: 24px;
      padding: 0;
      border: 2px solid $primaryColor;
      position: absolute;
      left: -40px;
      top: 4px;
      transition: background 0.4s ease-in-out,
                  color 0.4s ease-in-out;

      @include respond-to(tablet-min) {
        left: -40px;
      }

      @include respond-to(laptop-min) {
        left: -48px;
      }

      @include respond-to(desktop-min) {
        top: 4px;
        width: 32px;
        height: 32px;
        left: -62px;
      }
    }

    .image {
      background-size: auto 100%;
    }
  }

  &.img-right {
    .image {
      left: inherit;
      right: 0;
    }


    .inner {
      position: relative;
      top: 0;
      transform: none;
    }

    img {
      margin: 0;
      float: right;
      top: 0;
      transform: none;
      transform-origin: right center;

      @include respond-to(phone-max) {
        padding-left: 16px;
      }


      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
}
