.contact-section {
  padding: 32px 0 56px;

  @include respond-to(tablet-min) {
     padding: 40px 0 56px;
  }

  @include respond-to(desktop-min) {
    padding: 80px 0 104px;
  }
}
