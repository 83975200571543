//PRIMARY PALETTE
$primaryColor: #2975C2;
$secondaryColor: #38A2E3;
$tertiaryColor: #FBE25E;

//ACCENT COLOURS
$mainGrey: #14171A;
$darkGrey: #15171A;
$lightGrey: #F7F9FA;
$white: #fff;
$black: #000;

.bg-primary-color {
  background: $primaryColor;
  color: $white;
}

.bg-secondary-color {
  background: $secondaryColor;
  color: $white;
}

.bg-lightgrey {
  background: $lightGrey;
}

.primary-color {
  color: $primaryColor;
}
