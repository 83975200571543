html,
body {
  position: relative;
  width: 100%;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.page-wrapper {
  padding: 80px 0 0;

  @include respond-to(laptop-min) {
    padding: 112px 0 0;
  }

  @media only screen and (min-width: 992px) and (max-width: 1140px) {
    padding: 104px 0 0;
  }
}
