@keyframes pin-pulsate {
  0% {
    transform: rotateX(55deg) scale(0.1, 0.1);
    opacity: 0;
  }

  50% {

    opacity: 1;
  }

  100% {
    transform: rotateX(55deg) scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes left-arrow-anim {
  from,
  to {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-6px);
  }
}

@keyframes right-arrow-anim {
  from,
  to {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(6px);
  }
}

@keyframes circle-bg-anim {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes arrow-left-anim {
  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(4px);
  }

  75% {
    transform: translateX(-4px);
  }
}

@keyframes arrow-right-anim {
  0%,
  100% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-4px);
  }

  75% {
    transform: translateX(4px);
  }
}
