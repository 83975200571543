.horizontal-list-item {
  position: relative;
  margin: 0 0 56px;

  @include respond-to(tablet-min) {
    margin: 0 0 16px;
  }

  .index {
    position: relative;
    display: block;
    margin: 0 auto;
    font-family: $mainFontBold;
    font-size: 32px;
    padding: 8px 0 18px;
    line-height: 1;
    width: 62px;
    border: 2px solid $white;
    margin-bottom: 28px;
    background-color: $secondaryColor;
    box-shadow: 0 0 0 10px rgba($secondaryColor, 1);
    transition: color 0.4s ease-in-out,
                background 0.4s ease-in-out;

    @media only screen and (max-width: $laptop-max-size) {
      width: 46px;
      font-size: 26px;
      padding: 6px 0 12px;
    }
  }

  .img-holder {
    padding: 0 0 24px;

    @include respond-to(tablet-min) {
      padding-bottom: 40px;
    }

    img {
      max-width: 220px;
      width: 100%;

      @include respond-to(tablet-min) {
        max-width: 160px;
      }

      @include respond-to(laptop-min) {
        max-width: 220px;
      }

      @include respond-to(desktop-min) {
        max-width: 260px;
      }
    }
  }

  &::before {
    @include respond-to(tablet-min) {
      content: "";
      position: absolute;
      top: 30px;
      left: 50%;
      background: $white;
      height: 2px;
      width: 100%;

      @media only screen and (min-width: $tablet-min-size) and (max-width: $laptop-max-size) {
        top: 22px;
      }
    }
  }

  &:last-child {
    margin: 0 0 24px;

    @include respond-to(tablet-min) {
      margin: 0;
    }

    &::before {
      display: none;
    }
  }

  &:first-child {
    @include respond-to(phone-max) {
      margin-top: 16px;
    }
  }

  h3 {
    display: block;
    margin: 0;
  }

  &:hover,
  &:focus {
    .index {
      color: $secondaryColor;
      background: $white;
    }
  }
}
