@font-face {
  font-family: 'cera_greekregular';
  src: url('/fonts/cera_greek_regular.woff2') format('woff2'),
    url('/fonts/cera_greek_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'cera_greekbold';
    src: url('/fonts/cera_greek_bold.woff2') format('woff2'),
         url('/fonts/cera_greek_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

$mainFont: 'cera_greekregular';
$mainFontBold: 'cera_greekbold';
