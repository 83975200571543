.before-after-strip {
  padding: 0 0 56px;

  @include respond-to(desktop-min) {
    padding: 0 0 64px;
  }

  .col-heading {
    background: $secondaryColor;
    color: $white;
    padding: 16px;
    margin-top: 32px;

    @include respond-to(tablet-min) {
      margin-top: 56px;
      margin-bottom: 56px;
    }

    &:first-child {
      margin-top: 0;
    }

    h2 {
      font-size: 20px;
      text-transform: none;

      @include respond-to(tablet-min) {
        font-size: 24px;
      }

      @include respond-to(desktop-min) {
        font-size: 32px;
      }
    }
  }

  .col-holder {
    margin: 32px 0 0;

    @include respond-to(tablet-min) {
      margin: 32px 0;
    }
  }

  .col {
    float: left;
    max-width: 50%;
    padding: 0;

    &::before {
      content:"";
      display: table;
      clear: both;
    }

    img {
      border-right: 1px solid $white;
    }

    &.after-col {
      img {
        border-right: none;
        border-left: 1px solid $white;
      }
    }

    &:hover,
    &:focus {
      .caption {
        padding: 8px 16px;
        background-size: 100% 100%;

        transition: transform 0.25s linear 0.4s,
              padding 0.25s ease-in-out 0.25s,
              background 0.25s linear 0.4s;

        h3 {
          color: $white;
          transition: color 0.25s linear 0.4s;
        }
      }
    }
  }

  .img-holder {
    margin: 0;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 100%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &:hover,
    &:focus {
      &::before {
        animation: circle-bg-anim 0.6s ease-out;
      }
    }
  }

  .caption {
    @include gradient-horizontal(rgba($secondaryColor, 1), rgba($secondaryColor, 1));

    background-repeat: no-repeat;
    background-size: 100% 0%;
    background-position: left top;
    text-align: center;
    margin: 0;
    padding: 16px 16px 0;
    transition: transform 0.25s ease-in-out 0.25s,
                padding 0.25s ease-in-out 0.25s,
                background 0.25s ease-in-out;

    h3 {
      margin: 0;
      text-transform: uppercase;
      color: $mainGrey;
      transition: color 0.25s ease-in-out;
    }
  }
}
